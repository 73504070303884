import '@assets/bordered-table.css';
import { useEffect, useState } from 'react';
import { contractAssessmentApi } from '@services/contractAssessmentApi';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';

function DocumentContent(props) {
  const value = props.data;
  const [data, setData] = useState();
  const apiRequest = useHttpPrivateRequest();
  const fetchData = async() => {
    try {
      const ids = value.map((row) => {
        return row.id;
      });
      const request = await apiRequest(
        contractAssessmentApi.getDetail(ids)
      );
      const response = request?.data;
      setData(response?.data);
    } catch (error) {
      setData(null);
    }
  }
  
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div style={{ fontFamily: 'Time New Roman' }}>
      <h4 className="text-center">
        PERNYATAAN PENETAPAN KINERJA
        <br />
        POLITEKNIK KESEHATAN TANJUNGKARANG KEMENKES RI
      </h4>

      <div style={{ textAlign: 'center', margin: '10px' }}>
        <img
          style={{ width: '100px' }}
          src={'/assets/img/logo/logo.png'}
          alt="logo"
        />
      </div>

      <h4 className="text-center">PENETAPAN KINERJA TAHUN {new Date(data.datePrint).getFullYear()}</h4>
      <p style={{ textAlign: 'justify' }}>
        Dalam rangka mewujudkan manajemen pemerintahan yang efektif, transparan,
        dan akuntabel serta berorientasi pada hasil, kami yang bertanda tangan
        di bawah ini :
      </p>

      <table>
        <thead>
          <tr>
            <td style={{ width: '30%' }}>Nama</td>
            <td>:</td>
            <td>{data.name}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Golongan</td>
            <td>:</td>
            <td>{data.unit} </td>
          </tr>
        </tbody>
      </table>
      <p className="tex-justify">
        Selanjutnya disebut pihak <b>pertama</b>
      </p>

      <table>
        <thead>
          <tr>
            <td style={{ width: '30%' }}>Nama</td>
            <td>:</td>
            <td>{data.supervisor}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Golongan</td>
            <td>:</td>
            <td>{data.unitSupervisor}</td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: 'justify' }}>
        Selaku atasan langsung pihak pertama, selanjutnya disebut pihak{' '}
        <b>kedua</b>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Pihak pertama berjanji akan mewujudkan target kinerja yang seharusnya
        sesuai lampiran perjanjian ini, dalam rangka mencapai target kinerja
        jangka menengah seperti yang telah ditetapkan dalam dokumen perencanaan.
        Keberhasilan dan kegagalan pencapaian target kinerja tersebut menjadi
        tanggung jawab kami.
      </p>

      <p style={{ textAlign: 'justify' }}>
        Pihak kedua akan memberikan supervisi yang diperlukan serta akan
        melakukan evaluasi terhadap capaian kinerja dari perjanjian ini dan
        mengambil tindakan yang diperlukan dalam rangka pemberian penghargaan
        dan sanksi.
      </p>

      <table>
        <thead>
          <tr>
            <td style={{ width: '35%' }}></td>
            <td style={{ width: '30%' }}></td>
            <td style={{ width: '35%' }}>Bandar Lampung, {new Date(data.datePrint).toLocaleDateString()}</td>
          </tr>
          <tr style={{ height: '100px', verticalAlign: 'top' }}>
            <td>
              <b>Pihak kedua,</b>
            </td>
            <td></td>
            <td>
              <b>Pihak Pertama,</b>
            </td>
          </tr>

          <tr>
            <td>{data.supervisor} </td>
            <td></td>
            <td>{data.name} </td>
          </tr>
          <tr>
            <td>NIP. {data.nipSupervisor}</td>
            <td></td>
            <td>NIP. {data.nip}</td>
          </tr>
        </thead>
      </table>

      <div className="page-break" style={{ marginTop: '30mm' }}></div>

      <h4 style={{ textAlign: 'center' }}>
        PERJANJIAN KINERJA TAHUN 2023 <br />
        POLITEKNIK KESEHATAN TANJUNGKARANG KEMENKES RI
      </h4>

      <table className="bordered-table">
        <thead>
          <tr>
            <th rowSpan="2">No </th>
            <th rowSpan="2">Kinerja Atasan Langsung</th>
            <th rowSpan="2">Program/Kegiatan</th>
            <th rowSpan="2">Indikator Kinerja Program/ Kegiatan (IKP/IKK)</th>
            <th rowSpan="2"> ""</th>
            <th colSpan="2">Target</th>
          </tr>
          <tr>
            <th>Semester</th>
            <th>Tahunan</th>
          </tr>
          <tr>
            <th>(1)</th>
            <th>(2)</th>
            <th>(3)</th>
            <th>(4)</th>
            <th>(5)</th>
            <th>(6)</th>
            <th>(7)</th>
          </tr>
        </thead>
        <tbody>
         
        {
            data.contactAssement.length > 0 && data.contactAssement.map((item, index) => (
              <tr>
                <td>{index+1}.</td>
                <td>{item.cascading}</td>
                <td>{item.program}</td>
                <td>{item.indicator}</td>
                <td>{item.unit}</td>
                <td style={{ textAlign: 'center' }}>{item.semester}</td>
                <td style={{ textAlign: 'center' }}>{item.target_year}</td>
              </tr>
            )) 
          }
        </tbody>
      </table>

      <br />
      <table>
        <thead>
          <tr>
            <td style={{ width: '35%' }}></td>
            <td style={{ width: '30%' }}></td>
            <td style={{ width: '35%' }}>Bandar Lampung, {new Date(data.datePrint).toLocaleDateString()}</td>
          </tr>
          <tr style={{ height: '100px', verticalAlign: 'top' }}>
            <td>
              <b>Pihak kedua,</b>
            </td>
            <td></td>
            <td>
              <b>Pihak Pertama,</b>
            </td>
          </tr>

          <tr>
            <td>{data.supervisor} </td>
            <td></td>
            <td>{data.name} </td>
          </tr>
          <tr>
            <td>NIP. {data.nipSupervisor}</td>
            <td></td>
            <td>NIP. {data.nip}</td>
          </tr>
        </thead>
      </table>
    </div>
  );
}

export default DocumentContent;
